import { provide, ref, reactive, computed, watch, getCurrentInstance } from 'vue';

const useDialog = () => {
  //建立參數
  const dialogVisible = ref(false);
  const dialogTemplate = ref(null);
  const dialogName = ref(null);
  const title = ref(null);
  const funcName = ref(null);
  let styleData = reactive({});
  let cus = reactive({});
  let chooseVal = reactive({});

  //打開彈窗
  const doOpenDialog = (dialog) => {
    dialogTemplate.value = dialog.dialogTemplate;
    dialogName.value = dialog.dialogName;
    title.value = dialog.dialogTitle;
    funcName.value = dialog.submitFunc;
    styleData.style = dialog.style;
    cus.cusProps = dialog.cusProps;
    dialogVisible.value = true;
  };

  //觸發父元件的function
  const { proxy } = getCurrentInstance();
  const submit = (val) => {
    chooseVal.data = val;
    proxy[funcName.value](chooseVal.data);
  };

  const openDialog = (name, cusProps = {}) => {
    closeDialog();
    const data = getDialogData(name);
    if (data.cusProps) {
      data.cusProps = { ...data.cusProps, ...cusProps };
    } else {
      data.cusProps = cusProps;
    }
    doOpenDialog(data);
  };

  //關閉彈窗
  const closeDialog = () => {
    dialogVisible.value = false;
    dialogTemplate.value = null;
  };

  //監聽彈窗關閉
  watch(dialogVisible, () => {
    if (!dialogVisible.value) dialogTemplate.value = null;
  });

  //彈窗資料
  const getDialogData = (val) => {
    const data = computed(() => {
      return {
        searchMeal: {
          dialogTemplate: 'SearchMealDialog',
          dialogName: 'searchMeal',
        },
        searchOrder: {
          dialogTemplate: 'SearchDialog',
          dialogName: 'searchOrder',
        },
        searchReport: {
          dialogTemplate: 'ReportDialog',
          dialogName: 'searchReport',
        },
        searchAnalysis: {
          dialogTemplate: 'AnalysisDialog',
          dialogName: 'searchAnalysis',
        },
        ManualDiscountSetting: {
          dialogTemplate: 'ManualDialog',
          dialogName: 'ManualDiscountSetting',
          submitFunc: 'refreshList',
        },
        autoDiscountSetting: {
          dialogTemplate: 'AutoDialog',
          dialogName: 'autoDiscountSetting',
          submitFunc: 'refreshList',
        },
        storeGiftSetting: {
          dialogTemplate: 'StoreGiftDialog',
          dialogName: 'storeGiftSetting',
          submitFunc: 'refreshList',
        },
        mealSetting: {
          dialogTemplate: 'MealDialog',
          dialogName: 'mealSetting',
          submitFunc: 'refreshList',
        },
        notificationSetting: {
          dialogTemplate: 'NotificationDialog',
          dialogName: 'notificationSetting',
          submitFunc: 'refreshList',
        },
        newsSetting: {
          dialogTemplate: 'NewsDialog',
          dialogName: 'newsSetting',
          submitFunc: 'refreshList',
        },
        searchShopPos: {
          dialogTemplate: 'ShopPosDialog',
          dialogName: 'searchShopPos',
        },
        searchAccount: {
          dialogTemplate: 'AccountDialog',
          dialogName: 'searchAccount',
        },
        mediaSetting: {
          dialogTemplate: 'MediaDialog',
          dialogName: 'mediaSetting',
          submitFunc: 'refreshList',
        },
        marqueeSetting: {
          dialogTemplate: 'MarqueeDialog',
          dialogName: 'marqueeSetting',
          submitFunc: 'refreshList',
        },
        searchManual: {
          dialogTemplate: 'SearchManualDialog',
          dialogName: 'searchManual',
        },
        searchAuto: {
          dialogTemplate: 'SearchAutoDialog',
          dialogName: 'searchAuto',
        },
        searchStoreGift: {
          dialogTemplate: 'SearchStoreGiftDialog',
          dialogName: 'searchStoreGift',
        },
        createPermission: {
          dialogTemplate: 'createPermissionDialog',
          dialogName: 'createPermission',
        },
        editRoleName: {
          dialogTemplate: 'editRoleNameDialog',
          dialogName: 'editRoleName',
        },
        searchNews: {
          dialogTemplate: 'SearchNewsDialog',
          dialogName: 'searchNews',
        },
        productCategorySetting: {
          dialogTemplate: 'CategoryDialog',
          dialogName: 'productCategorySetting',
          submitFunc: 'refreshList',
        },
        searchProductCategory: {
          dialogTemplate: 'SearchCategoryDialog',
          dialogName: 'searchProductCategory',
        },
      };
    });
    return data.value[val];
  };

  //提供給子元素參數
  provide('dialogVisible', dialogVisible);
  provide('name', dialogName);
  provide('close', closeDialog);
  provide('submit', submit);
  provide('title', title);
  provide('style', styleData);
  provide('cusProps', cus);

  return { dialogVisible, dialogTemplate, dialogName, openDialog, closeDialog, getDialogData };
};

export { useDialog };
