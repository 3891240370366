<template>
  <div class="search-condition-wrap search-descriptions-account">
    <div class="button-wrap">
      <el-button type="info" plain style="font-weight: normal; border: none; width: 110px" @click="clearCondition">
        清除條件
      </el-button>
      <el-button type="primary" style="width: 110px" @click="openDialog('searchAccount')">修改條件</el-button>
    </div>
    <el-descriptions :column="2" size="large" style="width: 100%" v-if="getSearchCondition">
      <el-descriptions-item label-class-name="descriptions-label" label="帳號" min-width="260px">
        {{ getSearchCondition.user_id || '未設定' }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-label" label="類別" min-width="260px">
        {{ getSearchCondition.permission_id.split(' ')[1] || '未設定' }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-label" class-name="descriptions-content" label="可登入門市">
        {{
          getSearchCondition.shop_id.length > 0
            ? getSearchCondition.shop_id.map((e) => e.split(' ')[1] + ' ' + e.split(' ')[2]).join('/')
            : '未設定'
        }}
      </el-descriptions-item>
    </el-descriptions>
  </div>

  <!-- 彈窗 -->
  <component :is="dialogTemplate"></component>
</template>
<script>
import { useRoute, useRouter } from 'vue-router';
import { useGetters, useMutations } from '@/utils/mapStore';
import { defineAsyncComponent, watch } from 'vue';
import { useDialog } from '@/utils/dialog';
export default {
  name: 'search-account',
  components: {
    AccountDialog: defineAsyncComponent(() => import('@/components/account/AccountDialog.vue')),
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    //取得條件
    const getSearchCondition = useGetters('account', ['getSearchCondition']);
    if (!getSearchCondition.value) router.push('/account/list?page=1');
    //清除條件
    const { CLEAR_SEARCH, SET_IS_DETAIL } = useMutations('account', ['CLEAR_SEARCH', 'SET_IS_DETAIL']);
    const clearCondition = async () => {
      SET_IS_DETAIL(false);
      CLEAR_SEARCH();
      router.push('/account/list?page=1');
    };
    watch(route, () => {
      if (!route.path.includes('search')) CLEAR_SEARCH();
    });
    //彈窗
    const { dialogTemplate, openDialog } = useDialog();
    return { getSearchCondition, clearCondition, dialogTemplate, openDialog };
  },
};
</script>
<style lang="scss" scoped>
.search-condition-wrap {
  @include white-bg;
  display: flex;
  align-items: center;
  padding: 16px 22px 0;
  position: relative;
  .button-wrap {
    position: absolute;
    top: 16px;
    right: 22px;
  }
  :deep(.descriptions-content) {
    width: calc(100vw - 440px);
  }
}
</style>
